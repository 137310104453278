/* global Paddle */
import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { auth, db } from "./Firebase"; // Firebase client SDK
import { doc, getDoc, setDoc, onSnapshot } from "firebase/firestore";
import { signInWithCustomToken } from "firebase/auth";
import LogoutButton from "./LogoutButton";
import { useNavigate } from "react-router-dom";

// Reusable function to exchange Auth0 token for Firebase custom token
export const authenticateWithFirebase = async (auth0Token) => {
  try {
    console.log("Authenticating with Firebase using custom token");

    const response = await fetch("/api/getFirebaseCustomToken", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ auth0Token }),
    });

    if (!response.ok) {
      throw new Error('Failed to fetch Firebase custom tokens: ${response.statusText}');
    }

    const { firebaseCustomToken } = await response.json();
    await signInWithCustomToken(auth, firebaseCustomToken);
    console.log("Successfully signed in to Firebase");
  } catch (error) {
    console.error("Error signing in to Firebase:", error);
    throw error;
  }
};

const Pay = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  // Basic local state for business info & Paddle data
  const [businessInfo, setBusinessInfo] = useState({ businessName: "", website: "" });
  const [isPaddleLoaded, setIsPaddleLoaded] = useState(false);
  const [billingCycle, setBillingCycle] = useState("year");
  const [prices, setPrices] = useState({
    annually: "$0 per year",
    monthly: "$0 per month",
  });

  // Environment variables for Paddle config
  const PADDLE_CLIENT_TOKEN = process.env.REACT_APP_PADDLE_CLIENT_TOKEN;
  const PRICE_ID_MONTHLY = process.env.REACT_APP_PADDLE_PRICE_ID_MONTHLY;
  const PRICE_ID_ANNUALLY = process.env.REACT_APP_PADDLE_PRICE_ID_ANNUALLY;

  // 1) On mount or when user changes, sign in & fetch business info
  useEffect(() => {
    const fetchBusinessInfo = async () => {
      if (!user?.sub) return;

      try {
        // 1a) Exchange token and sign in once
        const auth0Token = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: "openid profile email",
        });
        await authenticateWithFirebase(auth0Token);

        // 1b) Read or create businessInfo doc
        const userDocRef = doc(db, "businessInfo", user.sub);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          setBusinessInfo(userDoc.data());
        } else {
          const defaultBusinessInfo = { businessName: "", website: "" };
          await setDoc(userDocRef, defaultBusinessInfo);
          setBusinessInfo(defaultBusinessInfo);
        }
      } catch (err) {
        console.error("Error fetching business info:", err);
      }
    };

    if (user) {
      fetchBusinessInfo();
    }
  }, [user, getAccessTokenSilently]);

  // 1c) Optional: real-time listener for subscription
  useEffect(() => {
    if (!user?.sub) return;

    const userDocRef = doc(db, "users", user.sub);
    const unsubscribe = onSnapshot(userDocRef, (snapshot) => {
      const data = snapshot.data();
      if (data?.activeSubscription) {
        console.log("Subscription just turned active => navigating to Client Portal");
        unsubscribe(); // Stop listening, so we don't loop
        navigate("/client-portal");
      }
    });
    return () => unsubscribe();
  }, [user, navigate]);

  // 2) Load Paddle script once
  useEffect(() => {
    if (window.Paddle) {
      setIsPaddleLoaded(true);
      fetchPrices();
      return;
    }

    const script = document.createElement("script");
    script.src = "https://cdn.paddle.com/paddle/v2/paddle.js";
    script.async = true;
    script.onload = () => {
      Paddle.Environment.set("sandbox"); // or "live" in production
      Paddle.Initialize({ token: PADDLE_CLIENT_TOKEN });
      setIsPaddleLoaded(true);
      fetchPrices();
    };
    script.onerror = () => console.error("Failed to load Paddle script.");
    document.body.appendChild(script);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // run once

  // 3) Fetch Paddle prices
  const fetchPrices = async () => {
    if (!window.Paddle) {
      console.error("Paddle script not loaded yet.");
      return;
    }

    const fetchPrice = async (priceId) => {
      try {
        const response = await Paddle.PricePreview({ items: [{ quantity: 1, priceId }] });
        return response.data.details.lineItems[0].formattedTotals.subtotal.replace(".00", "");
      } catch (error) {
        console.error('Error fetching price for ${priceId}:, error');
        return "$0";
      }
    };

    try {
      const [annualPrice, monthlyPrice] = await Promise.all([
        fetchPrice(PRICE_ID_ANNUALLY),
        fetchPrice(PRICE_ID_MONTHLY),
      ]);
      setPrices({
        annually: '$${annualPrice} per year',
        monthly: '$${monthlyPrice} per month',
      });
    } catch (err) {
      console.error("Error fetching Paddle prices:", err);
    }
  };

  // 4) Checkout logic
  const openCheckout = async () => {
    if (!businessInfo.businessName || !businessInfo.website) {
      alert("Please fill in both Business Name and Business Website before proceeding.");
      return;
    }

    if (!isPaddleLoaded) {
      console.error("Paddle script not loaded yet.");
      return;
    }

    try {
      // Merge business info into Firestore "users" doc
      const userDocRef = doc(db, "users", user?.sub);
      await setDoc(userDocRef, businessInfo, { merge: true });

      const selectedPriceId = billingCycle === "month" ? PRICE_ID_MONTHLY : PRICE_ID_ANNUALLY;

      Paddle.Checkout.open({
        displayMode: "inline",
        frameTarget: "checkout-container",
        frameInitialHeight: "450",
        frameStyle: "width: 100%; min-width: 312px; backgroundColor: 'transparent'; border: 'none';",
        items: [{ priceId: selectedPriceId, quantity: 1 }],
        customer: { email: user?.email },
        customData: {
          userId: user?.sub,
          businessName: businessInfo.businessName,
          website: businessInfo.website,
        },
        // No automatic redirect or successCallback here
        errorCallback: (error) => {
          console.error("Error during checkout:", error);
          alert("An error occurred. Please try again.");
        },
      });
    } catch (error) {
      console.error("Error starting checkout:", error);
    }
  };

  // 5) Render UI
  return (
    <div style={{ fontFamily: "Arial, sans-serif", padding: "20px" }}>
      <LogoutButton />

      <h1>Choose Your Plan</h1>
      <form>
        <label>
          Business Name:
          <input
            type="text"
            name="businessName"
            placeholder="Required"
            value={businessInfo.businessName}
            onChange={(e) => setBusinessInfo({ ...businessInfo, businessName: e.target.value })}
            required
          />
        </label>
        <br />
        <label>
          Business Website:
          <input
            type="text"
            name="website"
            placeholder="Required"
            value={businessInfo.website}
            onChange={(e) => setBusinessInfo({ ...businessInfo, website: e.target.value })}
          />
        </label>
      </form>

      <p>14 day free trial and then:</p>
      <div style={{ marginBottom: "20px" }}>
        <div style={{ marginBottom: "10px" }}>
          <label>
            <input
              type="radio"
              name="billingCycle"
              value="year"
              checked={billingCycle === "year"}
              onChange={() => setBillingCycle("year")}
            />
            {prices.annually}
          </label>
        </div>
        <div>
          <label>
            <input
              type="radio"
              name="billingCycle"
              value="month"
              checked={billingCycle === "month"}
              onChange={() => setBillingCycle("month")}
            />
            {prices.monthly}
          </label>
        </div>
      </div>

      <div id="checkout-container" style={{ marginBottom: "20px" }}></div>

      <button onClick={openCheckout}>Pay</button>

      
    </div>
  );
};

export default Pay;