import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "./Firebase";

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, isLoading, user } = useAuth0();
  const [isSubscriptionChecked, setIsSubscriptionChecked] = useState(false);
  const [hasActiveSubscription, setHasActiveSubscription] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const checkSubscription = async () => {
      if (!user?.sub) return;

      try {
        const userDocRef = doc(db, "users", user.sub);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          console.log("User document found:", userData);
          setHasActiveSubscription(!!userData.activeSubscription);
        } else {
          console.log("No user document found. Creating default document...");
          await setDoc(userDocRef, { activeSubscription: false });
          setHasActiveSubscription(false);
        }
      } catch (error) {
        console.error("Error checking subscription in Firestore:", error);
      } finally {
        setIsSubscriptionChecked(true);
      }
    };

    if (isAuthenticated) {
      checkSubscription();
    } else {
      setIsSubscriptionChecked(true);
    }
  }, [user, isAuthenticated]);

  if (isLoading || !isSubscriptionChecked) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    console.log("User not authenticated. Redirecting to home...");
    return <Navigate to="/" state={{ from: location }} />;
  }

  // if (!hasActiveSubscription && location.pathname !== "/pay") {
  //   console.log("User authenticated but no active subscription. Redirecting to /pay...");
  //   return <Navigate to="/pay" state={{ from: location }} />;
  // }

  console.log("User authenticated and authorized. Rendering protected content...");
  return children;
};

export default ProtectedRoute;
