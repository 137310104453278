import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { db } from './Firebase'; // Import your Firebase setup
import { doc, setDoc, getDoc } from 'firebase/firestore';

const LoginButton = () => {
  const { loginWithRedirect, isAuthenticated, user, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const createUserInFirestore = async () => {
      if (!isAuthenticated || !user?.sub) return;

      try {
        // Get the user's unique Auth0 ID
        const userId = user.sub;

        // Reference the Firestore document for this user
        const userDocRef = doc(db, 'users', userId);

        // Check if the user document already exists
        const userDocSnap = await getDoc(userDocRef);

        if (!userDocSnap.exists()) {
          console.log(`Creating a new Firestore document for user: ${userId}`);

          // Set default data for the new user
          const userData = {
            email: user.email,
            name: user.name || '',
            activeSubscription: false, // Default to no subscription
            createdAt: new Date().toISOString(),
          };

          await setDoc(userDocRef, userData);
          console.log(`User document created for: ${userId}`);
        }
      } catch (error) {
        console.error('Error creating Firestore user document:', error);
      }
    };

    if (isAuthenticated) {
      createUserInFirestore();
    }
  }, [isAuthenticated, user]);

  return <button onClick={() => loginWithRedirect()}>Login</button>;
};

export default LoginButton;
