import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FoodForm from './FoodForm';
import './App.css'; // Use App.css for styling
import LoginButton from './LoginButton';
import LogoutButton from './LogoutButton'; // Import LogoutButton
import { useAuth0 } from '@auth0/auth0-react';

const Home = () => {
  const [showForm, setShowForm] = useState(false);
  const [isFoodDeliverySelected, setIsFoodDeliverySelected] = useState(false); // Track if a food delivery option is selected
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth0(); // Access authentication state

  // Handle showing the form
  const handleStartClick = () => {
    setShowForm(true);
  };

  // Callback to update the state when a food delivery option is selected
  const handleFoodDeliverySelection = (selectedOption) => {
    setIsFoodDeliverySelected(!!selectedOption); // Update state based on whether an option is selected
  };

  return (
    <div className="">
      <header className="header">
        <div className="auth-buttons">
          {/* Show both Login and Logout buttons */}
          <LoginButton />
          <LogoutButton />
        </div>
        <h1>Collaborative Catering</h1>
        <div className="homeHeader">
          <p>Smart Intake Form</p>
          <p>On Your Website</p>
        </div>
      </header>

      <section className="demo-section">
        {!showForm ? (
          <div className="demo-prompt">
            <button className="start-button" onClick={handleStartClick}>
              Demo
            </button>
          </div>
        ) : (
          <div className="food-form-section">
            <FoodForm
              defaultConfig={{
                primaryColor: '#3498DB',
                secondaryColor: '#AEDFF7',
                contrastColor: '#ea9b47',
                backgroundColor: '#FFFFFF',
                fontPrimary: 'Open Sans',
              }}
              isEmbedded={true}
              onFoodDeliveryChange={handleFoodDeliverySelection} // Pass the callback as a prop
            />
          </div>
        )}
      </section>

      <footer className="site-footer">
        {isFoodDeliverySelected && (
          <div className="sign-up-footer">
            <button className="sign-up-button" onClick={() => navigate('/signup')}>
              Sign Up
            </button>
          </div>
        )}
      </footer>
    </div>
  );
};

export default Home;
