import React, { useEffect } from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import Home from './Home';
import ClientPortal from './ClientPortal';
import TemplateForm from './TemplateForm';
import Pay from './Pay';
import ProtectedRoute from './ProtectedRoute';
import Share from './Share';
import { useAuth0 } from '@auth0/auth0-react';

console.log('App.js: Rendering App component');

function App() {
  const { user, isAuthenticated } = useAuth0();

  useEffect(() => {
    console.log('App component mounted');
    return () => {
      console.log('App component unmounted');
    };
  }, []);

  return (
    <div className="App-wrapper">
      <div className="App">
        <header className="App-header">
          <Routes>
            {console.log('App.js: Setting up routes')}
            <Route path="/" element={<Home />} />
            <Route
              path="/client-portal"
              element={
                <ProtectedRoute>
                  {console.log('App.js: Rendering ClientPortal with ProtectedRoute')}
                  <ClientPortal />
                </ProtectedRoute>
              }
            />
            <Route
              path="/template-form"
              element={
                <ProtectedRoute>
                  {isAuthenticated && user?.sub ? (
                    <TemplateForm clientId={user.sub} />
                  ) : (
                    <div>Loading...</div>
                  )}
                </ProtectedRoute>
              }
            />

            <Route
              path="/pay"
              element={
                <ProtectedRoute>
                  {console.log('App.js: Rendering Pay with ProtectedRoute')}
                  <Pay />
                </ProtectedRoute>
              }
            />
            <Route
              path="/subscription-billing"
              element={
                <ProtectedRoute>
                  {console.log('App.js: Rendering Pay for /subscription-billing with ProtectedRoute')}
                  <Pay />
                </ProtectedRoute>
              }
            />
            <Route path="/share" element={<Share />} />
          </Routes>
        </header>
      </div>
    </div>
  );
}

export default App;
