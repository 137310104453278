import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { auth, db } from './Firebase'; // Firebase client SDK
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { signInWithCustomToken } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import LogoutButton from './LogoutButton';
import { authenticateWithFirebase } from './Pay'; // Import the shared function

// Utility to generate a random publicId
function generatePublicId() {
  return Math.random().toString(36).substr(2, 9);
}

function ClientPortal() {
  const { user, isAuthenticated, getAccessTokenSilently, logout } = useAuth0();
  const navigate = useNavigate();

  // State variables
  const [activeSubscription, setActiveSubscription] = useState(false);
  const [config, setConfig] = useState({
    primaryColor: '',
    secondaryColor: '',
    contrastColor: '',
    backgroundColor: '',
    fontPrimary: '',
    emailAddress: '',
  });
  const [embedCode, setEmbedCode] = useState('');

  // Paddle customer portal URL
  const customerPortalUrl = 'https://sandbox-customer-portal.paddle.com/cpl_01j8b0jkek534kde6wrcmv2gry';

  useEffect(() => {
    async function init() {
      try {
        if (!isAuthenticated || !user?.sub) {
          console.log('User not authenticated—redirecting to /');
          navigate('/');
          return;
        }

        // Exchange Auth0 token -> Firebase custom token -> sign in to Firestore
        const auth0Token = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: 'openid profile email',
        });
        await authenticateWithFirebase(auth0Token);

        // Check Firestore user doc for subscription
        const userDocRef = doc(db, 'users', user.sub);
        let userDocSnap = await getDoc(userDocRef);

        if (!userDocSnap.exists()) {
          console.log('Creating default Firestore document for new user:', user.sub);
          await setDoc(userDocRef, { activeSubscription: false }); // Default to no active subscription
          userDocSnap = await getDoc(userDocRef); // Refresh the document snapshot
        }

        if (!userDocSnap.data()?.activeSubscription) {
          console.log('No active subscription found—redirecting to /pay');
          navigate('/pay');
          return;
        }

        console.log('User has an active subscription:', user.sub);
        setActiveSubscription(true);

        // Fetch or create a config doc in 'users'
        const configDocRef = doc(db, 'users', user.sub);
        const configDocSnap = await getDoc(configDocRef);

        let userConfig;
        if (configDocSnap.exists()) {
          console.log('Existing config found:', configDocSnap.data());
          userConfig = configDocSnap.data();
        } else {
          console.log('No config found; creating default config...');
          userConfig = {
            primaryColor: '#3498DB',
            secondaryColor: '#AEDFF7',
            contrastColor: '#ea9b47',
            backgroundColor: '#FFFFFF',
            fontPrimary: 'Open Sans, sans-serif',
          };
          await setDoc(configDocRef, userConfig);
        }

        setConfig(userConfig);

        // Ensure a publicId is set for embedding
        let { publicId } = userConfig;
        if (!publicId) {
          publicId = generatePublicId();
          userConfig.publicId = publicId;
          await setDoc(configDocRef, { publicId }, { merge: true });
        }

        // Build embed code
        const snippet = `<div id="food-form-container"></div>
<script src="https://collaborative-catering-21ab4e9a8bfb.herokuapp.com/embed-script/${publicId}"></script>`;
        setEmbedCode(snippet);
      } catch (error) {
        console.error('Error loading client portal:', error);
        navigate('/');
      }
    }

    init();
  }, [isAuthenticated, user, getAccessTokenSilently, navigate]);

  // Handler for config changes
  function handleChange(e) {
    setConfig({ ...config, [e.target.name]: e.target.value });
  }

  // Handler for saving config updates
  async function handleSubmit(e) {
    e.preventDefault();
    try {
      const configDocRef = doc(db, 'users', user.sub);
      await setDoc(configDocRef, config, { merge: true });
      alert('Configuration updated successfully.');
    } catch (error) {
      console.error('Error updating config:', error);
      alert('Failed to update the configuration. Please try again.');
    }
  }

  // Logout wrapper
  function handleLogout() {
    localStorage.removeItem('isLoggedIn');
    logout({
      returnTo: window.location.origin,
      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
    });
  }

  // If the user doesn't have an active subscription or if we're still checking, show a spinner
  if (!activeSubscription) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ padding: '20px' }}>
      <LogoutButton onClick={handleLogout} />
      <h1>Client Portal</h1>

      <button
        onClick={() => {
          window.location.href = customerPortalUrl;
        }}
        style={{
          backgroundColor: '#007BFF',
          color: '#FFF',
          padding: '10px 20px',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          marginBottom: '20px',
        }}
      >
        Manage Billing / Cancel Subscription
      </button>

      <form onSubmit={handleSubmit}>
        <label>
          Primary Color:
          <input
            type="text"
            name="primaryColor"
            value={config.primaryColor}
            onChange={handleChange}
          />
        </label>
        <br />

        <label>
          Secondary Color:
          <input
            type="text"
            name="secondaryColor"
            value={config.secondaryColor}
            onChange={handleChange}
          />
        </label>
        <br />

        <label>
          Contrast Color:
          <input
            type="text"
            name="contrastColor"
            value={config.contrastColor}
            onChange={handleChange}
          />
        </label>
        <br />

        <label>
          Background Color:
          <input
            type="text"
            name="backgroundColor"
            value={config.backgroundColor}
            onChange={handleChange}
          />
        </label>
        <br />

        <label>
          Font Primary:
          <input
            type="text"
            name="fontPrimary"
            value={config.fontPrimary}
            onChange={handleChange}
          />
        </label>
        <br />

        <label>
          Email Address:
          <input
            type="email"
            name="emailAddress"
            value={config.emailAddress || ''}
            onChange={handleChange}
            required
          />
        </label>
        <br />

        <button type="submit">Save Configuration</button>
      </form>

      {embedCode && (
        <div style={{ marginTop: '20px' }}>
          <h3>Embed This Form on Your Website</h3>
          <p>Copy and paste the following code snippet into your website:</p>
          <div
            style={{
              backgroundColor: '#f6f8fa',
              padding: '15px',
              borderRadius: '5px',
              border: '1px solid #ddd',
              fontFamily: 'monospace',
              fontSize: '14px',
              overflowX: 'auto',
              whiteSpace: 'pre',
            }}
          >
            <code>
              {`<div id="food-form-container"></div>
<script src="https://collaborative-catering-21ab4e9a8bfb.herokuapp.com/embed-script/${config.publicId}"></script>`}
            </code>
          </div>
          <div style={{ marginTop: '10px', textAlign: 'center' }}>
            <button
              onClick={() => {
                navigator.clipboard.writeText(embedCode);
                alert('Embed code copied to clipboard!');
              }}
              style={{
                backgroundColor: '#007BFF',
                color: '#FFF',
                border: 'none',
                borderRadius: '5px',
                padding: '8px 16px',
                cursor: 'pointer',
              }}
            >
              Copy
            </button>
          </div>
        </div>
      )}



    </div>
  );
}

export default ClientPortal;
